<template>
  <v-container class="text-top">
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="450"
        transition="dialog-top-transition"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-alert dense type="success">
            {{ title }}
          </v-alert>
          <v-card-text>
            <div class="my-4 text-subtitle-1">{{ message }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click.native="agree"> 확인 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },

  methods: {
    open(title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
